.seller-data {
    min-height: 400px;
    position: absolute;
    top: 10%;
    left: 8%;
    margin: 0 auto;
    background-color: white;
    border: 1px solid #00ad73;
}

.title-header {
    font-weight: bold;
    color: #063e89
}