#cart {
  height: 100% !important;
  margin-bottom: 20px;
}

 .accommodation,
 .items {
  margin-top: 100px !important;
}

 .pills {
  display: flex;
  flex-direction: row;
  margin-top: 80px;
}

 .services-wrapper {
  margin-top: 1px;
  border-top: solid 1px rgb(228, 228, 228);
  min-height: 400px;
}

 .pill {
  border: solid 1px lightgray;
  border-bottom: none;
}

 .pill:first-child {
  border-right: none;
}

/* Mobile first */
 .pill button {
  display: inline-block;
  width: 191px;
  padding: 15px 0;
  border: none;
  background-color: white;
  font-size: 16px;
  font-weight: 700;
  color: #063e89;
  cursor: pointer;
  font-family: 'Montserrat', 'sans-serif';
}

 .pill.active button {
  background-color: #063e89;
  color: white;
}

 .pill:hover button {
  background-color: #f1f1f1;
}

 .pill.active:hover button {
  background-color: #063e89;
  color: white;
}

input.ui.dropdown {
  width: 60% !important;
}

select.ui.dropdown {
  width: 60% !important;
}

@media screen and (max-width: 1024px) {
  select.ui.dropdown{
    width: 95% !important;
    margin:auto;
  }
  input.ui.dropdown {
    width: 95% !important;
    margin:auto;
  }
}

#object label,
 label,
#labelText {
  font-size: 16px !important;
}

.payment-summary {
  text-align: right;
  margin-right: 40px;
  margin-bottom: 20px;
}

.txt-strong {
  color: #696969;
}

.order-info-wrapper {
  margin-left: 3rem;
  margin-top:50px;
  display: flex;
  flex-direction: row;
  transition: all 1s ease-in-out;
}

@media only screen 
  and (max-device-width: 1024px)  {
    .order-info-wrapper{
      flex-direction: column;
      margin-left: 1rem;
    }
}

.order-info-row-wrapper {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
}

