.discount-code-input-group{
    display: flex;
    flex-direction: row;
    margin-top: 25px;
}

.discount-code-input{
    margin-right: 10px !important;
    max-width: 450px;
}

.discount-code-btn{
    background-color: var(--orange) !important
}