.category-wrapper {
  padding: 0 60px;
  margin-top:30px;
}

.asterisk-text{
  width: 100%;
  text-align: left;
  margin-left: 60px;
  color: var(--orange);
}

@media only screen and (max-width: 570px) {
  .category-wrapper {
    padding: 0 32px;
  }
}


/* Mobile first */
 .cards-wrapper {
  display: flex;
  flex-direction: column;
  /* justify-content: space-around; */
  justify-content: initial;
  margin-top: 55px;
}

@media only screen and (min-width: 576px) {
  .cards-wrapper {
    flex-direction: row;
    flex-wrap: wrap;
  }
}