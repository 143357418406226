.slider {
  position: relative;
}

/* .range-bar12 {
  position: absolute;
  top: 56px;
  left: 215px;
  width: 15px;
  height: 15px;
  background-color: #00ad73;
  transform: rotate(44deg);
} */

@media only screen and (max-width: 576px) {
  .range-bar12 {
    top: 49px;
    left: 125px;
  }
}

/* .range-bar24 {
  position: absolute;
  top: 56px;
  right: 20px;
  width: 15px;
  height: 15px;
  background-color: #00ad73;
  transform: rotate(44deg);
} */



.value {
  width: 30px;
  height: 30px;
  padding: 6px;
  border-radius: 5px;
  background-color: #00ad73;
  text-align: center;
  color: white;
}

.rangeslider .rangeslider__handle {
  background: #00ad73;
  border: none;
}

.rangeslider .rangeslider__handle:focus {
  outline: none;
}

.rangeslider-horizontal .rangeslider__handle:after {
  content: none;
}

/* Range slider */

.rangeslider {
  box-shadow: none;
}

.rangeslider-horizontal {
  background-color: rgba(0, 173, 115, 0.5);
}

/* Range filled bar */
.rangeslider-horizontal .rangeslider__fill {
  background-color: #00ad73;
}




