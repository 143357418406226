.checkbox-container {
    display: block;
    font-size: 1.4rem;
    padding: 5px;
    font-weight: 500;
}

.discount-value-container {
    font-size: 1.8rem;
    font-weight: 700;
    text-align: center;
}

.discount-value{
    color: var(--green);
    font-weight: 700;
    padding: 10px;
}

.discount-note{
    margin-top:20px;
    font-size: 0.9rem;
}