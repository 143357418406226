.flag {
    width: 40px;
    height: 40px;
    margin-left: 25px;
    cursor: pointer;
}

 .flag.icon-de {
    background: url('../../../assets/img/flags/icon-11.png') no-repeat;
    background-size: cover;
}

.flag.icon-en {
    background: url('../../../assets/img/flags/icon-2.png') no-repeat;
    background-size: cover;
}

.flag.icon-fr {
    background: url('../../../assets/img/flags/icon-10.png') no-repeat;
    background-size: cover;
}

.flag.icon-ua {
    background: url('../../../assets/img/flags/icon-6.png') no-repeat;
    background-size: cover;
}

.flag.icon-pl {
    background: url('../../../assets/img/flags/icon-3.png') no-repeat;
    background-size: cover;
}

.flag.icon-el {
    background: url('../../../assets/img/flags/icon-16.png') no-repeat;
    background-size: cover;
}

.flag.icon-ru {
    background: url('../../../assets/img/flags/icon-9.png') no-repeat;
    background-size: cover;
}

.language-selector .flag {
    margin-left: 3px
}