input[type='submit'] {
    padding: 17px 75px;
    margin-bottom: 20px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    text-transform: uppercase;
}

input[type='submit']:focus { outline: 0px; }

.input[type='submit']:disabled{
    background-color: #b9b5b5;
}

.custom-button {
    padding: 17px 75px;
    margin-bottom: 20px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    text-transform: uppercase;
}

.custom-button:focus { outline: 0px; }

.custom-button:disabled{
    background-color: #b9b5b5;
}

.btn:disabled{
    background-color: #b9b5b5;
}

/* Go back button */
.prev-btn {
    background-color: #dddddd !important;
    color: #063e89 !important;
}

@media only screen and (max-width:480px) {
    .prev-btn {
        left: 29px;
        padding: 17px 50px !important;
    }
}

.prev-btn:hover { background: linear-gradient(90deg,#bebebe 0,#dddddd 50%, #bebebe); }

/* Next button */
.next-btn {
    background-color: #ee6410 !important;
    color: white !important;
}

@media only screen and (max-width:480px) {
    .next-btn {
        left: 44px;
        padding: 17px 50px !important;
    }
}

.next-btn:hover { 
    background: linear-gradient(90deg,#ee6410 0,#fa8942 50%,#ee6410) 
}