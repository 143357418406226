.wrapperContainer{
    justify-content: right;
}

.currency-wrapper{
    margin-left: 20px;
    top: 0;
}

.payment-summary{
    text-align: end;
    margin-top: 40px;
}

.modules-footer-wrapper{
    justify-content: flex-end;
}

.sub-text{
    font-size: 20px;
    font-weight: 200;
    color: #063e89;
}

.one-time-text{
    font-size: 12px;
    font-weight: 500;
    color: #ee6410;
    margin-bottom: 10px;
}



.service-spinner-wrapper{
    width: 100%;
    text-align: center;
    margin-top: 25px;
    margin-bottom: 5px;
}
