/* ------------------------ */
/*     GENERAL FEATURES     */
/* ------------------------ */
#root{
  height: 100%;
}

html { scroll-behavior: smooth; }

* {
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat', sans-serif;
  font-weight: normal;
  src: ();
  font-display: swap;
}

/* width */
::-webkit-scrollbar { width: 10px; }

/* Track */
::-webkit-scrollbar-track { background: #f1f1f1; }
 
/* Handle */
::-webkit-scrollbar-thumb { background: #063e89; }

/* Handle on hover */
::-webkit-scrollbar-thumb:hover { background: #05336e; }

h4 {
  letter-spacing: 1px;
  color: #444444;
}

p {
  font-size: 15px;
}

@media only screen and (max-width: 1000px) {
  p {
    font-size: 13px;
  }
}

.text-error::placeholder {
  color:  #d5000099 !important;       
}

.text-error {
  border-color: #d5000099 !important
}

.row {
  margin-right: 0;
  margin-left: 0;
}


select,
input[type="number"],
option {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  color: rgb(105, 105, 105) !important
}

input{
  border:none;
  background-color: transparent;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

label.title {
  color: #063e89 !important;
  font-size: 21px !important;
  font-weight: bold;
  position: relative;
}

label.title::after {
  content: '';
  position: absolute;
  top: 33px;
  left: 14px;
  width: 100px;
  height: 5px;
  background: #00ad73;
  border-radius: 5px;
  left: 0;
}

@media only screen and (max-width: 480px) {
  label {
    font-size: 16px !important
  }
}

.container-fluid {
  padding: 0
}

.rigth-container {
  margin-left: 130px !important
}

@media only screen and (max-width: 480px) {
  .rigth-container {
    margin-left: 0 !important
  }
}

.hidden {
  display: none;
}

.hidden-soft > * {
  display: none;
}

.hidden-scale {
  transform: scale(0, 0);
}

.visible-scale {
  scale: 1;
}

select.text-error {
  color:  #d5000099 !important; 
}

.btn-container > button {
  width: 150px;
  height: 50px;
}

.btn-container {
  display: flex;
  justify-content: space-between;
  padding: 0px 30px 0px 30px;
  margin-top: 100px;
  margin-bottom: 100px;
}

@media screen and (max-width: 480px) {
  .btn-container {
    flex-direction: column;
    justify-content: space-evenly;
  }

  .btn-container > button {
    width: 100%;
    margin-bottom: 10px !important;
  }
}


.ui.loader {
  display: flex;
}