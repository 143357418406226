/* Range slider container */
 .range-slider {
    padding: 80px 0;
    background-color: #f1f1f1
}

 .text {
    font-size: 16px;
    font-weight: bold;
    color: #063e89;
}

 .text-green {
    font-size: 16px;
    font-weight: bold;
    color: #00ad73;
}

.currency-select{
    max-width: 200px;
}

