/* Mobile first */
#topBar {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  background-color: #063e89;
  color: white;
}

/* Mobile first */
#topBar p {
  font-size: 12px
}

@media only screen and (min-width: 480px) {
  #topBar {
    display: flex;
    flex-direction: row;
    background-color: #063e89;
    color: white;
  }
}

#topBar > div:first-child,
#topBar > div:nth-child(2),
#topBar > div:nth-child(3) {
  border-right: 1px solid #a0a0a0;
}

@media only screen and (max-width: 992px) {
  #topBar > div { border-right: none !important; }

  #topBar .steps { border-bottom: 1px solid #a0a0a0; }
}

#topBar .steps {
  padding-top: 6px;
  padding-bottom: 6px;
}

#topBar .steps-text { text-transform: uppercase }

#topBar .stepbar-info,
#topBar .steps-text {
  height: 100%;
  min-height: 55px;
  width: 100%;
  text-align: center;
  color: white;
  position: relative;
  top: 30%;
}

#topBar .minutes { text-align: left } 

#topBar .active { background-color: #00ad73; }

@media only screen and (max-width: 570px) {
  #topBar .options-text { text-align: center; }
}


#topBar .speedometerContainer {
  align-items: right;
  float: right;
}
/* Icon */
#topBar .speedometer {
  background: url("../../../assets/img/speedometer-icon.png") no-repeat center center;
  background-position: right;
  height: 55px;
  left: 0;
}

#topBar .time-container {
  display: flex;
  flex-direction: row;
  margin: 0;
  padding-top: 6px;
  padding-bottom: 6px;
  background-color: #f1f1f1;
}

@media only screen and (max-width: 570px) {
  #topBar .time-container { padding-left: 35px; }
}

#topBar .time-container div:nth-child(2) { padding: 0; }

#topBar .time-container .stepbar-info {
  padding: 0;
  font-weight: 500;
  color: #063e89;
}

#topBar .info-container {
  padding-top: 5px;
  padding-bottom: 10px;
  padding-left: 40px;
}

@media only screen and (max-width: 570px) {
  #topBar .info-container .stepbar-info { margin-left: 0; }
}
