#agreements .agreements-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 15px !important;
  font-weight: 500 !important;
  color: #444444 !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

#agreements p {
  padding-left: 0px !important;
  font-weight: 500;
  color: #444444;
  cursor: text;
}

/* Hide the browser's default checkbox */
#agreements .agreements-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
#agreements .tick, #agreements #tick-policy {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: white;
  border: 2px solid #a0a0a0;
  border-radius: 5px;
}

/* On mouse-over, add a grey background color */
#agreements .agreements-container:hover input ~ .tick, #agreements .agreements-container:hover input ~ #tick-policy {
  background-color: #f1f1f1;
}

/* When the checkbox is checked, add a blue background */
#agreements .agreements-container input:checked ~ .tick, #agreements .agreements-container input:checked ~ #tick-policy {
  background-color: #00ad73;
  border: none;
}

/* Create the tick/indicator (hidden when not checked) */
#agreements .tick:after, #agreements #tick-policy:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the tick when checked */
#agreements .agreements-container input:checked ~ .tick:after, #agreements .agreements-container input:checked ~ #tick-policy:after {
  display: block;
}

/* Style the tick/indicator */
#agreements .agreements-container .tick:after, #agreements .agreements-container #tick-policy:after {
  left: 7px;
  top: 3px;
  width: 9px;
  height: 15px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
/* .more-information {
  position: relative;
  display: block;
} */

.arrow {
  position: absolute;
  top: 163px;
  left: 276px;
  right: 0px;
  width: 10px;
  margin: auto;
}

#modal > div.d-flex.justify-content-end.mt-5{
  margin-top: 0px !important;
}

#agreements #tick-policy.tick-error{
  background-color: #e66;
}


.read-more-text{
  color: var(--blue) !important
}

.read-more-hidden {
  opacity: 0 !important;
}

.read-more-paragraph {
  font-size: 0.85rem;
  opacity: 1;
  transition: opacity 1s ease-in-out;
}