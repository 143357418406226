.payment-options-container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    transition: transform 1s ease-in-out;
}


.payment-option-image{
    margin: 15px;
    
    cursor: pointer;
}

.payment-option-selected{
    border: 0.5px solid var(--blue);
}

.payment-option-container {
    display: flex;
    flex-direction: column;
}

.payment-option-container > *:last-child {
    flex-grow: 0;
}

.commision-container {
    font-size: 0.85rem;
    margin: auto;
    display: flex;
    justify-content: flex-end;
}