#modal #lang-modal .center-text{
   text-align: justify;
}

#modal #lang-modal .modal-checkbox{
    margin-right:5px;
}

#modal #lang-modal .modal-btn-group{
  position: fixed;
  bottom: 100;
  margin: auto;
}

.modal-text{
  white-space: break-spaces;
}

#modal.hidden {
  display: none !important;
}

#modal .logo {
  background: url("../../../assets/img/logo-color.svg") no-repeat;
  height: 100px;
}

#modal .close { 
  font-size: 22px;
  cursor: pointer;
}

.modal-head { position: relative; }

/* Tooltip styles*/
.tool-tip {
  display: none;
  top: -42px;
  right: -12px;
  font-family: 'Montserrat', sans-serif;
  font-size: 15px;
  letter-spacing: 1px;
  text-align: center;
  padding: 8px;
  position: absolute;
  width: 70px;
  height: 35px;
  background: #00ad73;
  color: white;
  border-radius: 5px
}

.tool-tip::after {
  position: absolute;
  bottom: -4px;
  left: 0;
  right: 0;
  margin: auto;
  content:'';
  width: 10px;
  height: 10px;
  background: #00ad73;
  transform: rotate(46deg)
}

#modal .close:hover .tool-tip {
  display: block;
}

#modal h2 {
  font-size: 30px;
  text-transform: uppercase;
  letter-spacing: 2px;
}

#modal hr.modal-line {
  width: 100%;
  height: 0 !important;
  background-color: var(--grey)
}

#modal .btn {
  padding: 10px 25px;
  font-size: 18px;
  box-shadow: 0 0 5px #a0a0a0;
}

#modal .btn.accept {
  background-color: #00ad73;
  color: white;
}

#modal .btn:hover        { background-color: #f1f1f1 }
#modal .btn.accept:hover { background-color: #00ad73      }

#options .options-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 15px !important;
  font-weight: 500 !important;
  color: #444444 !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

#options p {
  padding-left: 0px !important;
  font-weight: 500;
  color: #444444;
  cursor: text;
}

/* Hide the browser's default checkbox */
#options .options-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
#options .tick {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: white;
  border: 2px solid #a0a0a0;
  border-radius: 5px;
}

/* On mouse-over, add a grey background color */
#options .options-container:hover input ~ .tick {
  background-color: #f1f1f1;
}

/* When the checkbox is checked, add a blue background */
#options .options-container input:checked ~ .tick {
  background-color: #00ad73;
  border: none;
}

/* Create the tick/indicator (hidden when not checked) */
#options .tick:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the tick when checked */
#options .options-container input:checked ~ .tick:after {
  display: block;
}

/* Style the tick/indicator */
#options .options-container .tick:after {
  left: 7px;
  top: 3px;
  width: 9px;
  height: 15px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
/* .more-information {
  position: relative;
  display: block;
} */

.arrow {
  position: absolute;
  top: 163px;
  left: 276px;
  right: 0px;
  width: 10px;
  margin: auto;
}

#options{
  margin-top: 20px !important;
  padding-top: 20px !important;
  margin-left: 0px !important;
  padding-left: 0px !important;
}