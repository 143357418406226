#footer { 
    background-color: #063e89; 
    width: 100%; 
    bottom: 0;
    min-height: 75px;
    margin-top: -75px;
    padding-top:10px;
    display: flex;
    flex-direction: row;
    flex-basis: 50%;
    justify-content: space-around;
}

#footer > .footer-links {
    margin-left: 10px;
}

#footer .footer-img{
    background-color: #063e89; 
    margin: 10px;
}

@media only screen and (max-width: 600px){
    #footer {
        flex-direction: column;
        padding-left: 10px;
        padding-right: 10px;
    }

    #footer > div {
        margin-bottom: 20px;
        text-align: center;
    }
}

#footer p { 
    color: white;
    font-size: 12px; 
}
#footer p a { color: var(--yellow); }
