/* Mobile first */
.language-container {
    position: fixed;
    width: 85px;
    top: 125px;
    z-index: 10;
    right: 0px;
}

@media only screen and (max-width: 576px) {
    .language-container {
        top: 79px;
        right: 5px;
    }
}

.language-selector {
    padding: 10px 20px;
    border-radius: 5px 5px 5px 5px;
    background: #f1f1f1;
    cursor: pointer;
}

.language-selector h4 {
    color: white;
}