/* ------------------------- */
/*     OBJECTS CONTAINER     */
/* ------------------------- */

.resorts {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 45px;
}

#object .buildings {
  width: 120px;
  padding: 10px;
  border: 1px solid white;
  border-radius: 15px;
  cursor: pointer;
}

#object .input-selected {
  background-color: #f1f1f1;
  border: 0.7px solid #0a0a0a42;
}

@media only screen and (max-width: 570px) {
  #object .buildings {
    margin: 0;
    padding: 10px;
  }
}

#object .buildings:hover {
  background-color: #f1f1f1;
}

#object .buildings:checked {
  background-color: rgb(243, 243, 243);
  border: 1px solid #f1f1f1;
}

#object .icon {
  height: 100px;
}

/* Objects icons */
#object .icon.hotel {
  background: url("../../../assets/img/hotel-icon.png") no-repeat center center;
  background-size: 50px;
  width: 50px;
  height: 50px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
}
#object .icon.apartment {
  background: url("../../../assets/img/apartment-icon.png") no-repeat center center;
  background-size: 50px;
  width: 50px;
  height: 50px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
}
#object .icon.hostel {
  background: url("../../../assets/img/hostel-icon.png") no-repeat center center;
  background-size: 50px;
  width: 50px;
  height: 50px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
}
#object .icon.camping {
  background: url("../../../assets/img/camping-icon.png") no-repeat center center;
  background-size: 50px;
  width: 50px;
  height: 50px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
}

#object .buildings > p {
  text-align: center;
  font-size: 15px !important;
}

@media only screen and (max-width: 570px) {
  #object .buildings > p {
    font-size: 12px !important;
  }
}

#object .checked {
  max-width: 23%;
  padding-left: 0;
}

@media only screen and (max-width: 570px) {
  #object .checked {
    max-width: 50%;
  }
}

#object .checked input {
  display: none;
}



/* animations */

.buildings:active {
  transform: translateY(4px);
}

/* animations end */