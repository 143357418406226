#sumary .sumary { overflow: hidden }

#sumary .data {
    position: relative;
    min-height: 700px;
    padding: 0;
    margin: auto;
    margin-top: 80px;
    background-color: #00ad73;
    line-height: 30px;
    cursor: context-menu;
}

@media only screen and (max-width: 560px) {
    #sumary .data { margin-bottom: 80px !important; }
}

#sumary .skew {
    background-color: white;
    position: absolute;
    min-width: 200%;
    height: 100%;
    top: 45%;
    left: -30px;
    transform: skew(10deg, 5deg);
}

#sumary table.data-table td { 
    /* font-size: 16px  */
    font-size: 14px
}

@media only screen and (max-width: 560px) {
    #sumary table.data-table td { font-size: 14px }
}

#sumary table.data-table tr { padding: 20px 0 !important; }

#sumary .bill-sumary table i {
    font-size: 22px;
    color: #00ad73;
    padding: 0 10px
}

/* Range slider container */
#sumary .range-slider {
    padding: 80px 0;
    background-color: #f1f1f1
}

#sumary .text {
    font-size: 16px;
    font-weight: bold;
    color: #063e89;
}

#sumary .text-green {
    font-size: 16px;
    font-weight: bold;
    color: #00ad73;
}

#sumary .currency-select{
    max-width: 200px;
}


.bank-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.bank-wrapper{
    text-align: left;
}

.highlight > p {
    font-weight: 700;
}

.pay-btn {
    font-size: 15px !important;
}