.hidden-container{
    visibility: hidden;
  }
  
  .camping-control .offers { margin-top: 50px }
  
  .camping-control .offers > div {
      display: flex;
      justify-content: space-around;
      width: 75%;
  }
  
  .camping-control .question { margin-right: 20px }
  
  .camping-control .amount {
      display: inline !important;
      width: 120px !important;
  }
  
  .camping-control input[type="radio"]:nth-child(3) { margin-left: 20px }
  
  /* Customizable radio buttons */
  .container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 165px;
  }
  
  .input {
    display: block;
    position: relative;
    padding-left: 25px;
    margin-right: 10px;
    font-size: 15px;
    margin-bottom: 12px;
    cursor: pointer;
    user-select: none;
  }
    
  /* Esconde el radio button por defecto */
  .input input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
    

  .apartment-input {
    display: flex !important;
    justify-content: left !important;
  }

  /* Crea un radio button customizable */
  .checkmark {
    position: absolute;
    top: 3px;
    left: 0;
    width: 15px;
    height: 15px;
    background: lightgrey;
    border-radius: 100%;
  }
    
  /* Cambia color de fondo al hacer mouse-over */
  .input:hover input ~ .checkmark {
    background: grey
  }
    
  /* Cuando hemos chequeado el input añade un color de fondo */
  .input input:checked ~ .checkmark {
    background: #00ad73
  }
    
  /* Crea el indicador */
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }
    
  /* Muestra el indicador cuando esta chequeado */
  .input input:checked ~ .checkmark:after { display: block }
    
  /* Estilos del indicador */
  .input .checkmark:after {
    top: 4px;
    left: 4px;
    width: 7px;
    height: 7px;
    border-radius: 100%;
    background: white;
  }
  
  /* Second question */
  .items-container { margin-top: 20px }

