 .desc-font {
   font-size: 12px;
 }
#modules .discount-text{
  text-align: center;
  font-weight: 500;
  font-size: 2rem;
  padding-top: 15px;
}

 #modules .discount {
   width: 75px;
   height: 50px;
   background-color: rgb(237, 28, 36);
 }

 /* Discounts container */
 #modules .discount-tag {
   height: 50px;
   background-color: #063e89;
   
   letter-spacing: 1px;
   color: white;
 }

 /* ---------------------- */
 /*     INPUT CHECKBOX     */
 /* ---------------------- */

 /* On mouse-over, add a grey background color */
 #modules .container:hover input~.checkmark {
   background-color: #f1f1f1;
 }

 /* When the checkbox is checked, add a green background */
 #modules .container input:checked~.checkmark {
   background-color: #00ad73;
   border: none;
 }

 /* Create the checkmark/indicator (hidden when not checked) */
 #modules .checkmark:after {
   content: "";
   position: absolute;
   display: none;
 }

 /* Show the checkmark when checked */
 #modules .container input:checked~.checkmark::after {
   display: block;
 }

 #modules .container .checkmark.deselected::after {
   display: none !important;
 }

 /* #modules .container input:checked ~ .checkmark.deselected:after {
    display: none;
  } */

 /* Style the checkmark/indicator */
 #modules .container .checkmark:after {
   left: 8px;
   top: 2px;
   width: 9px;
   height: 17px;
   border: solid white;
   border-width: 0 2px 2px 0;
   -webkit-transform: rotate(45deg);
   transform: rotate(45deg);
 }

 /* Icons */
 #modules .icons {
   height: 100px;
 }


 /* It holds the checkbox and the checkmarck */
 #modules label.container {
   display: block;
   position: relative;
   top: 49px;
   height: 20px;
   margin-left: 0;
   cursor: pointer;
   font-size: 19px;
   user-select: none;
 }

 #modules .cards {
   width: 293px;
   height: 380px;
   margin: 0 20px;
   margin-bottom: 55px;
   padding: 0;
   background-color: white;
   box-shadow: 0px 0px 5px rgb(182, 181, 181);
 }

 #modules .cards.active {
   box-shadow: 0px 0px 8px 2px #6b6b6b;
   transform: scale(1.05);
 }


 #modules .price {
   font-size: 20px;
   font-weight: 200;
   color: #063e89;
   text-align: right;
   margin: auto;
   padding: 0;
 }


 #modules .additional-price {
   height: 20px;
   margin: 10px 0 0 0;
   justify-content: flex-end;
 }

 #modules .additional-price p {
   font-size: 12px;
   font-weight: 500;
   color: #ee6410;
 }

 #modules .separator {
   margin-bottom: 10px;
 }

 #modules .additional-price-individual {
   justify-content: flex-end;
   margin-bottom: 10px;
 }


 #modules .additional-price-individual p {
   font-size: 12px;
   font-weight: 500;
   color: #ee6410;
 }

 #modules .img-container {
   padding: 0;
   min-height: 50px;
   ;
 }

 #modules p {
   letter-spacing: 1px;
   color: #063e89;
 }

 /* Hide the browser's default checkbox */
 #modules .container input {
   position: absolute;
   opacity: 0;
   cursor: pointer;
   height: 0;
   width: 0;
 }

 /* Create a custom checkbox */
 #modules .checkmark {
   position: absolute;
   top: -113px;
   left: 250px;
   height: 27px;
   width: 27px;
   background-color: white;
   border: 2px solid #00ad73 !important;
   border-radius: 5px;
 }

 #modules .checkmark.deselected {
   background-color: white !important;
   border: 2px solid #00ad73 !important;
 }

 #modules .more-information {
   font-size: 18px;
 }

 #modules .fa-mouse-pointer {
   position: absolute;
   top: 20px;
   left: 100px;
 }

 #modules .module-icon {
   height: 50px;
   width: auto;
 }


 #modules button,
 .d-flex.justify-content-end select {
   font-size: 15px !important;
 }

 #modules .content .col-md-12 {
   font-size: 13px !important;
 }