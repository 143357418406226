#sumary .bill-sumary {
    width: 95%;
    margin: auto;
    margin-top: 100px;
    padding: 50px;
    border: 1px solid #00ad73;
    transition: transform 1s ease-in-out;
}

#sumary .bill-sumary table {
    margin-top:70px;
}

#sumary .bill-sumary label.form-title {
    display: block;
    margin: auto;
    text-align: center;
}

#sumary .bill-sumary .asterisk {
    text-align: end;
    margin-top: 10px;
    font-size: 12px;
}

#sumary .bill-sumary .text-price {
    font-weight: 500;
    color: #063e89;
    font-size: 22px !important;
}

#sumary .bill-sumary .text-price span {
    padding-left: 10px;
    font-weight: 600;
    color:  #444444;
}

#sumary .bill-sumary .total-container {
    padding-top: 50px;
    padding-bottom: 50px;
}

.configuration-fee{
    display: none;
}

.configuration-fee.active{
    display: contents;
}

