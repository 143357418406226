#app{
    height: 100% !important;
    background-color: #063e89;
}

/* background colors set this way because footer is offseting itself in different ways on different displays */
#wrapper{
    min-height: 100%;
    background-color: white;
    padding-bottom: 40px;
}

.kw-blue{
    color: #063e89;
}


.kw-pointer{
    cursor: pointer;
}