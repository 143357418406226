#CustomerData .form-container {
  border-right: 1px solid #dddddd;
}

.form-title {
  font-size: 20px !important;
  color: #00ad73 !important;
  text-transform: uppercase;
}

@media only screen and (max-width: 576px) {
  #CustomerData .form-title {
    font-size: 16px !important;
  }
}

#CustomerData .input-form {
  border-top: none;
  border-right: none;
  border-left: none;
  border-color: #063e8999;
  border-radius: 0px;
  font-size: 16px;
  font-weight: 400;
  color: #063e89 !important;
}

#CustomerData .input-form::placeholder {
  color: #063e8999;
}

select.input-form:focus,
option:focus {
  outline: none;
  appearance: none;
}

#CustomerData .input-form:focus {
  border-color: #063e89 !important;
}

#CustomerData .explanation-container {
  border: 1px solid #00ad73;
  padding: 20px 30px;
  font-size: 15px;
  color: #00ad73;
}

.btn.btn-light {
margin-top: 15px !important;
padding: 15px !important;
}

.error-message{
  color:red;
  font-size: 11px;
}