.selection-reminder{
    border-color: #ee6410  !important;
    animation: opacityDown 1s infinite alternate;
    -webkit-animation: opacityDown 1 infinite alternate;
    -moz-animation: opacityDown 1s infinite alternate;
    -o-animation: opacityDown 1s infinite alternate;
}

@keyframes opacityDown{
    0% {
        opacity: 0.25;
    }
    100%{
        opacity: 1;
    }
}

@-webkit-keyframes opacityDown{
    0% {
        opacity: 0.25;
    }
    100%{
        opacity: 1;
    }
}

@-moz-keyframes opacityDown{
    0% {
        opacity: 0.25;
    }
    100%{
        opacity: 1;
    }
}

@-o-keyframes opacityDown{
    0% {
        opacity: 0.25;
    }
    100%{
        opacity: 1;
    }
}